import React from 'react'
import {PageProps, Link} from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'

const SingUp = (props: PageProps) => (
  <Layout>
    <SEO title="Aanmelden" />

    <p>
      Indien u belangstelling heeft om uw kind aan te melden op onze school nodigen wij u van harte uit om contact op te nemen met onze directeur,
      Margo de Kruijf. U kunt haar bereiken op dit adres: &#8194;
      <a className="usefulLinks" href="mailto:margo@nederlandseschool.bg">
        margo@nederlandseschool.bg
      </a>
      .
    </p>
    <p>
      In overleg wordt een telefonisch kennismakingsgesprek georganiseerd en een proefles afgesproken. Indien u besluit uw kind aan te melden op onze
      school zal er vervolgens een intakegesprek plaatsvinden.
    </p>
    <p>
      Als het kind onvoldoende Nederlands spreekt behoudt de school het recht om de leerling niet toe te laten. Ook kan de school besluiten een kind,
      na een vooraf met de ouders afgesproken proefperiode, alsnog niet toe te laten.
    </p>
  </Layout>
)

export default SingUp
